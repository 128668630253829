<p>Korespondenční seminář z informatiky (dále KSI) je akcí Fakulty informatiky Masarykovy univerzity (dále FI).
  Potřebujeme zpracovávat některé Tvé osobní údaje; která a jak se dozvíš níže.</p>
<p>Máme údaje Tebou vyplněné v přihlášce, tedy: email, jméno, příjmení, pohlaví, adresu trvalého bydliště, název a
  adresu Tvé aktuální školy a předpokládaný kalendářní rok maturity. Též můžeš, ale nemusíš, vyplnit svou přezdívku,
  krátký profil, velikost trička a avatar.</p>
<p>
  Na co je používáme?
</p>
<ul>
  <li>Pokud se staneš úspěšným řešitelem či úspěšnou řešitelkou, mohou Ti být prominuty přijímací zkoušky na FI. Na to
    Tě ale FI potřebuje jednoznačně identifikovat. Studijnímu oddělení předáváme jméno, příjmení, pohlaví, bydliště a
    školu. Tyto informace taky používáme na případné rozeslání diplomů.
  </li>
  <li>Pokud budeš aktivní v aktuálním nebo minulém ročníku, propagační oddělení FI by Tě rádo začátkem školního roku
    informovalo o dalších aktivitách, které pro středoškoláky nabízí. Nejradši to dělají poštou.
  </li>
  <li>Zpracováváme data o úspěšnosti a řešení jednotlivých úloh a vlivů externích faktorů na něj, např. zda úlohu
    zvládají maturanti, ale mladší studenti ne. K tomu využíváme tvůj předpokládaný rok maturity.
  </li>
  <li>Na email ti posíláme věci relevantní ke KSI (o opravení úlohy, odpovědi v diskuzi, vydaní nové vlny, apod.) a
    také pozvánky na spřátelené akce. Po registraci si v nastavení můžeš vybrat, které druhy mailů chceš dostávat.
  </li>
</ul>

<p>Také uchováváme všechna řešení, která odevzdáš, jejich hodnocení, příspěvky v diskusích, či feedback k úlohám. Toto
  vše na FI analyzujeme a používáme ke zlepšení KSI (detekce problémů, kontrola opisování, …).</p>
<p>Též uchováme IP adresy spojení na servery KSI, abychom byly schopni dohledat technické problémy či útoky. Tvé heslo
  se po registraci ani změně hesla neukládá v žádné podobě, ze které by ho bylo rozumně možné získat zpět. Můžeme tedy
  pouze ověřit, že jsi při dalším přihlášení použil stejné heslo.</p>
<p>Tvá osobní data nezneužijeme k ničemu, co by nesouviselo s KSI nebo s dalšími aktivitami FI, a nepředáme je nikomu
  mimo tyto dvě organizace.</p>
<p>Veřejně vystavujeme pouze výsledkové listiny, obsahující Tvé jméno a příjmení, název školy a stát, ve kterém se
  škola nachází, statistiku tebou vyřešených úloh a získaných trofejí, a případně tvůj popisek profilu a avatar, pokud
  sis nějaký nastavil.</p>
<p>Celou naši databázi zálohujeme do externího úložiště. Děláme to tak, že ji lokálně zašifrujeme, a teprve šifrovanou
  ji posíláme k uschování. Ani provozovatel externího úložiště tedy nevidí obsah databáze.</p>
<p>Souhlas dáváš Masarykově univerzitě, jelikož FI MU není samostatným subjektem z pohledu GDPR.</p>
<h4>Souhlas se zpracováním osobních údajů</h4>
<p>Svojí registrací uděluji Masarykově univerzitě, Žerotínovo nám. 9, Brno (dále MU), souhlas se zpracováním mých osobních údajů,
  ke zpracování osobních údajů pro potřeby soutěže Korespondenční seminář informatiky (dále KSI). Souhlas uděluji pro
  všechny výše uvedené osobní údaje, a to po dobu účasti v semináři a 10 let poté. Též souhlasím s uchováním
  potřebných dat pro archivační účely i po této lhůtě (výsledkové listiny aj.).</p>
<p>MU tyto údaje zpracovává za účelem evidence řešitelů a účastníků KSI, k zajištění celoročního fungování semináře,
  analýze účinnosti a provádění jednotlivých propagačních akcí MU, zpravodajským účelům a vysokoškolským závěrečným
  pracím. Osobám, které o to projeví zájem v nastavení svého účtu, může MU také zasílat propagační materiály.</p>
<p>Údaje nebudou předány třetí osobě mimo MU (s vyjímkou šifrovaných záloh) ani využívány k jiným účelům, než ke
  kterým byly poskytnuty.</p>
<p>Tento souhlas uděluji ze své vlastní a svobodné vůle a beru na vědomí, že jej mohu kdykoliv odvolat zasláním
  e-mailu na adresu ksi@fi.muni.cz. Stejně tak může být požadováno vymazání i z archivních údajů KSI, pokud to bude
  technicky možné.</p>
<p>Dále máte právo:</p>
<ul>
  <li>požádat o informaci, jaké osobní údaje jsou o vás zpracovávány,</li>
  <li>požadovat opravu osobních údajů, pokud jsou neplatné nebo zastaralé,</li>
  <li>požadovat, aby nebyly vaše osobní údaje zpracovávány do doby, než bude vyřešena oprávněnost výše uvedených
    požadavků,
  </li>
  <li>požadovat, aby byly vaše osobní údaje předány jinému správci,</li>
  <li>podat stížnost u dozorového úřadu.</li>
</ul>
<p>V případě jakéhokoliv dotazu nebo uplatnění svých práv můžete kontaktovat buď organizátory (ksi@fi.muni.cz) nebo
  pověřence pro ochranu osobních údajů (poverenec@muni.cz).</p>
