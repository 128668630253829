<ng-template #template>
  <form (submit)="resetPassword()" [formGroup]="form">
    <input
      type="email"
      class="form-control"
      [placeholder]="'modal.login.email' | translate"
      formControlName="email"
    >

    <div *ngIf="showSuccess$ | async" class="alert alert-info" role="alert">
      {{'modal.reset-password.done' | translate}}
    </div>

    <button [disabled]="!form.valid" type="submit" class="btn btn-ksi w-100 btn-login">
      {{'modal.reset-password.submit' | translate}}
    </button>
  </form>
</ng-template>
