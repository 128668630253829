/**
 * web-backend-swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Category { 
    id: string;
    answer?: string | number;
    ftype: Category.FtypeEnum;
    text: string;
}
export namespace Category {
    export type FtypeEnum = 'stars' | 'line' | 'text_large';
    export const FtypeEnum = {
        Stars: 'stars' as FtypeEnum,
        Line: 'line' as FtypeEnum,
        TextLarge: 'text_large' as FtypeEnum
    };
}