<div class="about">
  <h1 class="name">
    <span>
      {{'root.navbar.title.long' | translate}}
    </span>
  </h1>
  <div class="images">
    <a (click)="toggleAboutInfo(0)" href="#">
      <img src="assets/img/about/seminar.svg"
           [alt]="'root.welcome.about.seminar.title' | translate"
           [class.active]="aboutInfoShown && aboutInfoSlide === 0"
      >
    </a>
    <a (click)="toggleAboutInfo(1)" href="#">
      <img src="assets/img/about/kscuk.svg"
           [alt]="'root.welcome.about.kscuk.title' | translate"
           [class.active]="aboutInfoShown && aboutInfoSlide === 1"
      >
    </a>
    <a (click)="toggleAboutInfo(2)" href="#">
      <img src="assets/img/about/achievements.svg"
           [alt]="'root.welcome.about.achievements.title' | translate"
           [class.active]="aboutInfoShown && aboutInfoSlide === 2"
      >
    </a>
  </div>
</div>

<div class="begin">
  <a [routerLink]="['/', routes.routes.tasks._]" class="tasks">
    <button class="btn-lg btn btn-ksi">{{'root.welcome.about.begin' | translate}}</button>
  </a>
  <a  class="more-info" [routerLink]="['/', routes.routes.about]">- {{'root.welcome.about.more-info' | translate}} -</a>
  <div class="subjects">
    <a href="#" (click)="toggleAboutInfo(0)" [class.active]="aboutInfoShown && aboutInfoSlide === 0">
      {{'root.welcome.about.seminar.title' | translate}}
    </a>
    <span>-</span>
    <a href="#" (click)="toggleAboutInfo(1)" [class.active]="aboutInfoShown && aboutInfoSlide === 1">
      {{'root.welcome.about.kscuk.title' | translate}}
    </a>
    <span>-</span>
    <a href="#" (click)="toggleAboutInfo(2)" [class.active]="aboutInfoShown && aboutInfoSlide === 2">
      {{'root.welcome.about.achievements.title' | translate}}
    </a>
  </div>
</div>

<carousel class="about-carousel"
          [activeSlide]="aboutInfoSlide"
          [collapse]="!aboutInfoShown"
          [showIndicators]="false"
          [interval]="0"
          [isAnimated]="true"
          (activeSlideChange)="onAboutSlideChange($event)"
>
  <slide>
    <div class="content">
      <span class="text">{{'root.welcome.about.seminar.text' | translate}}</span>
      <button class="btn-lg btn btn-go"
              [routerLink]="['/', routes.routes.tasks._]">
        {{'root.welcome.about.lets-start' | translate}}
      </button>
    </div>
  </slide>
  <slide>
    <div class="content">
      <span class="text">{{'root.welcome.about.kscuk.text' | translate}}</span>
      <button class="btn-lg btn btn-go" (click)="openKScuk()">
        {{'root.welcome.about.lets-start' | translate}}
      </button>
    </div>
  </slide>
  <slide>
    <div class="content">
      <span class="text">{{'root.welcome.about.achievements.text' | translate}}</span>
      <button class="btn-lg btn btn-go"
              [routerLink]="['/', routes.routes.achievements]"
      >
        {{'root.welcome.about.lets-start' | translate}}
      </button>
    </div>
  </slide>
</carousel>
<div class="about-carousel-border"></div>

<div class="articles-organisators-container">
  <div class="articles-container">
    <span class="header">{{'root.welcome.news.title' | translate}}</span>
    <ksi-spinner [source]="years.articles$"></ksi-spinner>
    <div class="articles">
      <ksi-article-preview
        *ngFor="let article of (years.articles$ | async)"
        class="article"
        [article]="article"
        [clickable]="true"
      >
      </ksi-article-preview>
    </div>
  </div>
  <div class="organisators-container">
    <span class="header">{{'root.welcome.organisators.title' | translate}}</span>
    <ksi-spinner [source]="years.organisators$"></ksi-spinner>
    <div class="organisators">
      <a *ngFor="let organiser of (years.organisators$ | async)"
        class="frame-organisator"
        [routerLink]="['/', routes.routes.profile._, organiser.id]"
      >
        <img [src]="organiser.profile_picture" alt="" class="pic">
        <span class="name">
          {{organiser.first_name | uppercase}}
          {{(organiser.nick_name ? '"' + organiser.nick_name + '"' : '') | uppercase}}
          {{organiser.last_name | uppercase}}</span>
      </a>
    </div>
  </div>
</div>
