/**
 * web-backend-swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ModuleSubmitResponse { 
    result: ModuleSubmitResponse.ResultEnum;
    report?: string;
    error?: string;
    message?: string;
    score?: number;
}
export namespace ModuleSubmitResponse {
    export type ResultEnum = 'ok' | 'error' | 'nok';
    export const ResultEnum = {
        Ok: 'ok' as ResultEnum,
        Error: 'error' as ResultEnum,
        Nok: 'nok' as ResultEnum
    };
}