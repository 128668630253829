<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'root.changelog.title' | translate}} <input [formControl]="controlSince" type="date"></h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngFor="let changeCategory of changeCategories">
      <h4>{{changeCategory}}</h4>
      <ul>
        <li *ngFor="let item of changes[changeCategory]">
          {{item.type}}: {{item.caption}} ({{item.date | date}})
        </li>
      </ul>
    </div>
  </div>
</ng-template>
