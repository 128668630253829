/**
 * web-backend-swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * A typed array of 8-bit unsigned integer values. The contents are initialized to 0. If the  requested number of bytes could not be allocated an exception is raised.
 */
export interface Uint8Array { 
}