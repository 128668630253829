/**
 * web-backend-swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CorrectionUpdateModuleEvaluation } from './correctionUpdateModuleEvaluation';

export interface CorrectionUpdate { 
    task_id: number;
    user: number;
    comment: number;
    achievements: Array<number>;
    modules: Array<CorrectionUpdateModuleEvaluation>;
}