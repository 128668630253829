/**
 * web-backend-swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Achievement } from './achievement';
import { Correction } from './correction';
import { CorrectionModule } from './correctionModule';
import { CorrectionTask } from './correctionTask';
import { Post } from './post';
import { Thread } from './thread';
import { ThreadDetail } from './threadDetail';

export interface CorrectionsAllResponse { 
    corrections: Array<Correction>;
    tasks: CorrectionTask;
    modules: Array<CorrectionModule>;
    achievements: Array<Achievement>;
    threads: Array<Thread>;
    posts: Array<Post>;
    threadDetails: Array<ThreadDetail>;
}