<div class="post">
  <div class="head">
    <ksi-users-inline [users]="[post.author]" [showRoles]="true"></ksi-users-inline>,
    {{post.published_at | ksiDate:'short'}}
  </div>
  <div class="content" [innerHTML]="post.body"></div>
  <div class="footer" *ngIf="allowActions">
    <a
      *ngIf="canEdit$ | async"
      class="edit clickable"
      tooltip="{{'discussion-thread.post.edit.tooltip' | translate}}"
      (click)="openEditModal()"
    >
      {{'discussion-thread.post.edit.title' | translate}}
    </a>
    <a
      class="reply clickable"
      tooltip="{{'discussion-thread.post.reply.tooltip' | translate}}"
      (click)="openReplyModal()"
    >
      {{'discussion-thread.post.reply.title' | translate}}
    </a>
    <a
      *ngIf="canDelete$ | async"
      class="delete clickable"
      tooltip="{{'discussion-thread.post.delete.tooltip' | translate}}"
      (click)="deletePost()"
    >
      {{'discussion-thread.post.delete.title' | translate}}
    </a>
    <a
      *ngIf="threadId !== null"
      class="link"
      [routerLink]="['/', routes.routes.discussion, threadId]"
      [fragment]="'' + post.id"
      tooltip="{{'discussion-thread.post.link' | translate}}"
    >
      #
    </a>
  </div>
</div>

<ng-container *ngIf="post.reaction.length">
  <div class="children-container" *ngIf="expanded && (maxLevel === null || level < maxLevel); else notExpanded">
    <div class="line-container">
      <div class="line" (click)="setExpanded(false)"></div>
    </div>
    <div class="children">
      <ksi-discussion-thread-posts
        class="child"
        *ngFor="let child of post.reaction"
        [postId]="child"
        [posts]="posts"
        [parent]="post"
        [threadId]="threadId"
        [level]="level + 1"
        [maxLevel]="maxLevel"
        [allowExpansion]="allowExpansion"
        [allowActions]="allowActions"
        (postsModified)="propagateModified()"
      >
      </ksi-discussion-thread-posts>
    </div>
  </div>
</ng-container>

<ng-template #notExpanded>
  <a *ngIf="allowExpansion"
    class="expand"
    (click)="setExpanded(true)"
    [routerLink]="['/', routes.routes.discussion, threadId]"
    [fragment]="maxLevelReached ? '' + post.id : undefined"
  >
    <span class="icon">{{icon.UNPACK}}</span>
    <span class="text">{{'discussion-thread.post.expand' | translate}}</span>
  </a>
</ng-template>
