/**
 * web-backend-swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ModuleGeneral } from './moduleGeneral';
import { ModuleProgramming } from './moduleProgramming';
import { ModuleQuiz } from './moduleQuiz';
import { ModuleSortable } from './moduleSortable';
import { ModuleText } from './moduleText';

export type KSIModule = ModuleProgramming | ModuleQuiz | ModuleSortable | ModuleGeneral | ModuleText;