<ng-template #template>
  <ksi-spinner *ngIf="!!showErr$" [source]="showErr$"></ksi-spinner>
  <form (submit)="login()" [formGroup]="form">
    <input
      type="email"
      class="form-control"
      [placeholder]="'modal.login.email' | translate"
      formControlName="email"
    >
    <input
      type="password"
      class="form-control"
      formControlName="password"
      [placeholder]="'modal.login.password' | translate"
    >
    <div *ngIf="showErr$ | async" class="alert alert-danger" role="alert">
      {{'modal.login.failed' | translate}}
    </div>

    <button [disabled]="!form.valid" type="submit" class="btn btn-ksi w-100 btn-login">
      {{'modal.login.login' | translate}}
    </button>

    <a class="text-center clickable" (click)="openRegisterModal()">{{'modal.login.register' | translate}}</a>
    <a class="text-center clickable" (click)="openForgottenPasswordModal()">{{'modal.login.forgotten-password' | translate}}</a>
  </form>
</ng-template>
