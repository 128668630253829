/**
 * web-backend-swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TaskDetails { 
    id: number;
    body: string;
    thread: number;
    modules: Array<number>;
    best_scores: Array<number>;
    comment: number;
    solution: string;
    achievements: Array<number>;
}