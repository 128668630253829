<ng-template #template>
  <form (submit)="saveReply()" [formGroup]="form">
    <input *ngIf="mode === 'new-thread'" class="form-control" formControlName="threadName" [placeholder]="'modal.post-reply.thread-name' | translate">
    <ksi-discussion-thread-posts
      *ngIf="post"
      [postId]="post.id"
      [maxLevel]="0"
      [posts]="posts!"
      [allowActions]="false"
      [allowExpansion]="false"
    ></ksi-discussion-thread-posts>
    <quill-editor class="reply-editor" formControlName="content"></quill-editor>
    <div class="post-size d-flex justify-content-end"
      [class]="{'text-danger': form.controls.content.value?.length > REPLY_MAX_LENGTH}"
    ><span>{{'modal.post-reply.total-size' | translate}}: {{form.controls.content.value?.length ?? 0}} / 8000</span></div>
    <button
      type="submit"
      class="btn-ksi btn save-reply w-100"
      [disabled]="!form.valid || form.disabled"
    >
      {{'modal.post-reply.save-reply' | translate}}
    </button>
  </form>
</ng-template>
