/**
 * web-backend-swagger
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserRole } from './userRole';

export interface BasicProfileResponseBasicProfile { 
    role: UserRole;
    gender: string;
    email: string;
    short_info: string;
    profile_picture: string;
    nick_name: string;
    last_name: string;
    first_name: string;
    signed_in: boolean;
    id: number;
}