<ksi-spinner [source]="thread$"></ksi-spinner>
<ng-container *ngIf="(thread$ | async) as thread">
  <ksi-discussion-thread-posts
    *ngFor="let post of (rootPost === null ? thread.thread.threadDetails.root_posts : [rootPost])"
    [postId]="post"
    [posts]="thread.posts"
    [threadId]="threadId"
    [maxLevel]="maxPostsDepth$ | async"
    (postsModified)="onPostsModified()"
  ></ksi-discussion-thread-posts>
  <button *ngIf="rootPost === null && allowNewRootPosts"
          class="btn btn-ksi w-100 new-post"
          (click)="openNewPostModal(thread.thread.threadDetails.id)"
  >
    {{'discussion-thread.post.new'|translate}}
  </button>
</ng-container>
