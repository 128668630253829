/**
 * EduLint web API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: contact@edulint.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Problem { 
    path?: string;
    source?: string;
    code?: string;
    symbol?: string;
    text?: string;
    line?: number;
    end_line?: number;
    column?: number;
    end_column?: number;
}